import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import callApiJson from './callApi';

interface SystemDesign {
  opportunityId: string;
  name: string;
  id: string;
  projectState: string;
}

const useSystemDesigns = (opportunityId: string | undefined) => useQuery({
  queryKey: ["systemDesigns", opportunityId],
  queryFn: async (): Promise<SystemDesign[]> => {
    try {
      const data = await callApiJson(`/api/system-designs?opportunityId=${opportunityId}`);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return data.map((x: any) => ({
        opportunityId: x.opportunity__c,
        name: x.Name,
        id: x.Id,
        projectState: x.project_state__c,
      }));
    } catch (error) {
      console.error(error);
      toast.warning('Failed to retrieve system designs, please retry', {
        position: toast.POSITION.TOP_RIGHT
      });
      throw new Error("Failed to retrieve system designs");
    }
  },
  enabled: !!opportunityId,
});

export default useSystemDesigns;
