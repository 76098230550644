import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import useSystemDesigns from '../../api/useOpportunitySDS';
import './CustomModal.css';
import { toast } from 'react-toastify';

function CustomModal({
  opportunityId,
  applicationId,
  businessName,
  projectState,
  onHide,
  ...props
}: {
  opportunityId: string;
  applicationId: string;
  businessName: string;
  projectState: string;
  onHide: () => void;
}) {
  const navigate = useNavigate();
  const { data: systemDesigns, isLoading, error } = useSystemDesigns(opportunityId);
  const [selectedSystemDesign, setSelectedSystemDesign] = useState<string>();

  if (error) {
    toast.warning('Failed to retrieve system designs, please retry', {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  const handleCreate = () => {
    if (selectedSystemDesign === undefined) {
      toast.warning("Please select a system design", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    if (projectState === undefined || projectState === null) {
      const errorMsg = "A project address is required in the opportunity to create a quote.  " + 
        "Please enter a project address in the associated opportunity";
      toast.warning(errorMsg, {
        position: toast.POSITION.TOP_RIGHT
      });
    } else {
      navigate('/calculator?opportunityId=' + opportunityId + 
      "&projectId=" + applicationId + 
      '&businessName=' + businessName + 
      (selectedSystemDesign !== undefined && selectedSystemDesign.length >  0 ? 
        "&systemDesign=" + selectedSystemDesign : ""));
    }
  }

  const handleSelectedSystemDesign = (e) => {
    if (systemDesigns && systemDesigns.length) {
      const selected = systemDesigns.filter(x => x.name === e.target.value);
      if (selected && selected.length) {
        setSelectedSystemDesign(selected[0].id);
      } else {
        setSelectedSystemDesign(undefined);
        }
    }
  }

  function renderOptions() {
    if (systemDesigns && systemDesigns.length) {
      const result = [<option key="none">None</option>];
      for (let i = 0; i < systemDesigns.length; i++) {
        result.push(
          <option key={systemDesigns[i].id}>
            {systemDesigns[i].name}
          </option>
        )
      }
      return result;
    }
    return (
      <option>None</option>
    )
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        </Modal.Title>
      </Modal.Header>
      <h2 className='modal-heading'>Generating SQ for {applicationId} {businessName}</h2>
      <Modal.Body className='modal-custom-body'>
        <h4 className='modal-subheading'>System Design</h4>
        <Form.Select style={{ width: '380px' }} onChange={handleSelectedSystemDesign}>
          {isLoading ? <option>Loading...</option> : renderOptions()}
        </Form.Select>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outlined" onClick={onHide}>Cancel</Button>
        <Button variant="contained" style={{ color: 'white', marginLeft: '10px' }} onClick={handleCreate}>Create Sales Quote</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CustomModal;